<template>
  <div class='JZContainer'>
    <el-form class="content_block" inline ref="JZForm" :model='JZForm' label-width='100px' :rules='JZFormRules'>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='公告类型：' prop='type'>
            <el-select :disabled="readonly" v-model="JZForm.type" placeholder='请选择公告类型'>
              <el-option label="系统维护" value="SYSTEM_MAINTENANCE"></el-option>
              <el-option label="行业资讯" value="INDUSTRY_INFORMATION"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-col :span='12'>
          <el-form-item label='公告标题：' prop='title'>
            <el-input  :disabled="readonly"  v-model.trim="JZForm.title" maxlength='50' show-word-limit placeholder="请输入公告标题"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="$route.query.status==='isDetail'&&status!=='WAIT_AUDIT'">
        <el-col :span='12'>
          <el-form-item label='发布时间：' prop='releaseTime'>
            <el-date-picker  type="datetime" :disabled="readonly"  placeholder="请选择发布时间" value-format="yyyy-MM-dd HH:mm:ss"  v-model.trim="JZForm.releaseTime"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="$route.query.status==='isDetail'&&status!=='WAIT_AUDIT'">
        <el-col :span='12'>
          <el-form-item label='发布人：' prop='createUserName'>
            <el-input  :disabled="readonly"  v-model.trim="JZForm.createUserName" placeholder="请输入发布人"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="$route.query.status==='isAduit'">
        <el-col :span='12'>
          <el-form-item label='创建时间：' prop='createTime'>
            <el-date-picker  type="datetime" :disabled="readonly"  placeholder="请选择创建时间" value-format="yyyy-MM-dd HH:mm:ss" v-model.trim="JZForm.createTime"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="$route.query.status==='isAduit'">
        <el-col :span='12'>
          <el-form-item label='创建人：' prop='createUserName'>
            <el-input  :disabled="readonly"  v-model.trim="JZForm.createUserName" placeholder="请输入创建人"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label='公告内容：' prop='content'>
        <wang-editor :readonly="readonly" :content="JZForm.content" maxlength='2000' show-word-limit  :getFullText="getEditorText"/>
      </el-form-item>
    </el-form>
    <BottomPanel v-if="$route.query.status==='isAduit'">
      <el-button type="default" @click="goBack" >返回</el-button>
      <el-button type="default" @click="handleAduit(false)" >驳回</el-button>
      <el-button type="primary" @click="handleAduit(true)" >通过</el-button>
    </BottomPanel>
    <BottomPanel v-else>
      <el-button type="default" @click="goBack" >返回</el-button>
      <el-button type="primary" v-if="$route.query.status!=='isDetail'" @click="handleEnsure" >{{$route.query.status==='isEditing'?'更新':$route.query.status==='isAdd'?'保存':''}}</el-button>
    </BottomPanel>
  </div>
</template>

<script>
import WangEditor from '@/components/common/editor';
import { dateFormat } from '@/utils/dateFormat';
import { announcementUpdate, announcementDetail, announcementAudit, announcementAdd } from '@/api/notice.js';
export default {
  name: '',
  components: {
    WangEditor,
  },
  methods: {
    getEditorText (text) {
      this.JZForm.content = text;
    },
    handleEnsure () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          if (this.$route.query.status === 'isEditing') {
            let currentParams = {
              ...this.JZForm,
              id: this.$route.query.id,
            };
            announcementUpdate(currentParams).then(res => {
              if (res.heads.code === 200) {
                this.goBack();
              } else {
                this.$message.error(res.heads.message);
              }
            });
          } else if (this.$route.query.status === 'isAdd') {
            announcementAdd(this.JZForm).then(res => {
              if (res.heads.code === 200) {
                this.goBack();
              } else {
                this.$message.error(res.heads.message);
              }
            });
          }
        }
      });
    },
    handleAduit (status) {
      let currentParams = {
        id: this.$route.query.id,
        auditResult: status,
      };
      announcementAudit(currentParams).then(res => {
        if (res.heads.code === 200) {
          this.goBack();
        } else {
          this.$message.error(res.heads.message);
        }
      });
    },
    goBack () {
      this.$router.go(-1);
    },
    getDetailAdInfo () {
      announcementDetail({id: this.$route.query.id}).then(res => {
        let currentInfo = {};
        if (res.heads.code === 200) {
          currentInfo = res.body;
        } else {
          this.$message.error(res.heads.message);
        }
        this.JZForm = {
          content: currentInfo.content,
          title: currentInfo.title,
          type: currentInfo.type,
        };
        this.status = currentInfo.status;
        if (this.$route.query.status === 'isAduit') {
          this.JZForm = {
            content: currentInfo.content,
            title: currentInfo.title,
            type: currentInfo.type,
            createUserName: currentInfo.createUserName,
            createTime: currentInfo.createTime ? dateFormat(currentInfo.createTime).format('YYYY-MM-DD HH:mm:ss') : '',
          };
        } else if (this.$route.query.status === 'isDetail') {
          this.JZForm = {
            content: currentInfo.content,
            title: currentInfo.title,
            type: currentInfo.type,
            createUserName: currentInfo.createUserName,
            releaseTime: currentInfo.releaseTime ? dateFormat(currentInfo.releaseTime).format('YYYY-MM-DD HH:mm:ss') : '',
          };
        }
      });
    },
  },
  created () {
    this.JZForm = {
      content: '',
      title: '',
      type: '',
    };
    if (this.$route.query.id) {
      this.getDetailAdInfo();
    }
  },
  computed: {
    readonly () {
      return ['isDetail', 'isAduit'].includes(this.$route.query.status);
    },
  },
  data () {
    return {
      JZForm: {
        content: '',
        title: '',
        type: '',
      },
      status: '',
      JZFormRules: {
        type: [
          {
            required: true,
            message: '请选择公告类型',
            trigger: ['blur', 'change'],
          },
        ],
        title: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: ['blur', 'change'],
          },
        ],
        content: [
          {
            required: true,
            message: '请输入公告内容',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/.el-form-item--small.el-form-item {
  display: flex;
}
/deep/ .el-form-item--small .el-form-item__content{
  flex: 1;
}
</style>
