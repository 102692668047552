<template>
    <!-- 富文本组件 -->
    <div ref="editor" class="wangeditorContainer"></div>
</template>
<script>
import E from 'wangeditor';
import AccountApi from '@/api/account';
import { uploadImg } from '@/api/wangEditor';
export default {
  name: 'editor',
  props: {
    getFullText: {
      // 回调函数callBack
      type: Function,
      default: () => { },
    },
    content: {
      // 回显数据
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
    },
  }, // 回调方法
  data () {
    return {
      editor: {},
      qiniuData: {
        key: '', // 图片名字处理
        token: '', // 七牛云token
        data: {},
      },
      downloadDomain: '',
      tokenExpireTime: 0,
    };
  },
  watch: {
    content (val) {
      if (val) {
        this.editor.txt.html(val);
        if (this.readonly) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      }
    },
  },
  mounted () {
    this.editor = new E(this.$refs.editor);
    this.editor.config.height = 200;
    this.editor.config.onchange = (html) => {
      this.getFullText(html); // 内容赋值
    };
    this.editor.config.showLinkImg = true;
    this.editor.config.linkImgCallback = function () {
    };
    this.editor.config.uploadImgServer
            = this.editor.config.uploadFileName = 'file';
    this.editor.config.zIndex = 8;
    this.editor.config.uploadImgServer = 'http://upload.qiniup.com';
    this.editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    this.editor.config.uploadImgParams = {
      from: 'editor',
    };
    this.editor.config.uploadImgHooks = {
    // 上传图片之前
      before: function (xhr) {
        console.log(xhr);

        // 可阻止图片上传
        return {
          prevent: true,
          msg: '需要提示给用户的错误信息',
        };
      },
      // 图片上传并返回了结果，图片插入已成功
      success: function (xhr) {
        console.log('success', xhr);
      },
      // 图片上传并返回了结果，但图片插入时出错了
      fail: function (xhr, editor, resData) {
        console.log('fail', resData);
      },
      // 上传图片出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        console.log('error', xhr, resData);
      },
      // 上传图片超时
      timeout: function (xhr) {
        console.log('timeout', xhr);
      },
      // 图片上传并返回了结果，想要自己把图片插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log('customInsert', result);

        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(result.data[0]);
      },
    };
    this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      let file = resultFiles[0];
      var formData = new FormData();
      formData.append('file', file);
      formData.append('data', file);
      formData.append('key', `${file.name}_${Date.now()}`);
      formData.append('token', this.qiniuData.token);
      uploadImg(formData).then(res => {
        let url = `${this.downloadDomain}/${res.key}`;
        insertImgFn(url);
      });
    };
    // 如果默认传递content值则渲染默认内容
    if (this.content) {
      this.editor.txt.html(this.content || '');
    }
    this.editor.create();

    if (this.readonly) {
      this.editor.disable();
    }
  },
  created () {
    this.getQiniuToken();
  },
  methods: {
    async getQiniuToken () {
      return AccountApi.getQiniuToken().then(res => {
        this.qiniuData.token = res.body.token;
        // 七牛token过期时间10分钟
        this.tokenExpireTime = new Date().getTime() + 10 * 60 * 60;
        this.downloadDomain = res.body.domain;
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .w-e-toolbar{
    flex-wrap:wrap;
  }
  .w-e-text p {
    width: 100%;
    word-wrap:break-word;
  }
  .wangeditorContainer{
    display: block;
    width: calc(100vw - 400px);
  }
</style>
